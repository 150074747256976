import React from 'react'
import './Footer.css'
import mobile from '../assets/mobile.png'
import email from '../assets/gmail.png'
import insta from '../assets/insta.png'
import whatsapp from '../assets/whatsapp.png'
import linkedin from '../assets/linkedin.png'
import twitter from '../assets/twitter.png'
import { Link } from 'react-router-dom'
import Contact from './Contact'
export default function Footer() {
  return (
   <body>
    <footer>
      <section class="top">
        <div class="links">
         {/*
          <div class="links-column">
            <h2>Portfolio</h2>
            <a>About</a>
            <a>Skills</a>
            <a>Attributes</a>
            <a>Projects</a>
            <a>Testimonials</a>
          </div>
          <div class="links-column">
            <h2>Resources</h2>
            <a>GitHub</a>
            <a>npm</a>
            <a>Codepen</a>
            <a>Codesandbox</a>
            <a>Dribbble</a>
          </div>
           */}
          <div class="links-column">
            <h2>Contact Us</h2>
           <Contact/>
          </div>
        </div>
      </section>
      <section class="bottom" style={{alignItems:"center",justifyContent:"center"}}>
        <p class="copyright">© 2024 All rights reserved</p>
        <div class="legal">
           <a> <Link style={{color:"white",textDecoration:"none",cursor:"pointer"}} to="/Terms&conditions">Terms & Conditions</Link> </a>
          <a> <Link style={{color:"white",textDecoration:"none",cursor:"pointer"}} to="/PrivacyPolicy">Privacy Policy</Link> </a>
        </div>
      </section>
    </footer>
  </body>
  )
}
