import React from 'react';
import './Contact.css'
import mobile from '../assets/mobile.png'
import email from '../assets/gmail.png'
import insta from '../assets/insta.png'
import whatsapp from '../assets/whatsapp.png'
import linkedin from '../assets/linkedin.png'
import twitter from '../assets/twitter.png'
const Contact = () => {
  return (
      <div className='contact' id="contact">
    <div className="contact-us">
      <div className="contact-info">
        <a className="info-item">
        <img src={mobile} className='contacticon'/>
          <span>+91 9959019781 </span>
         
        </a>
        <a className="info-item">
        
           <img src={email} className='contacticon'/>
          <span>sunanthsamala@shawavetechnologies.com</span>
          
        </a>
        <a className="info-item">
        
           <img src={insta} className='contacticon'/>
           
          <span> @DriverSpot</span>
          
        </a>
        <a className="info-item">
        
           <img src={whatsapp} className='contacticon'/>
          <span>+91 9959019781</span>
          
        </a>
        <a className="info-item">
        
           <img src={linkedin} className='contacticon'/>
          <span>Shawavetechnologies</span>
          
        </a>
        <a className="info-item">
        
           <img src={twitter} className='contacticon'/>
          <span> @Shawavetechnologies</span>
          
        </a>
      </div>
    </div>
    </div>
  );
};

export default Contact;
