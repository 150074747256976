import React from 'react';
import './Header.css';
import Lottie from 'lottie-react';
import headeranimation from '../assets/headeranimation.json'
const Header = () => {
  return (
   <header>
  <div class="header-content">
    <div class="text-container">
      <h1>Welcome to Shawave Technologies</h1>
      <h3>Your Solutions Partner for a Better Tomorrow</h3>
      <p style={{color:"gray",fontWeight:"600"}}>At Shawave, we are committed to providing innovative solutions to tackle everyday challenges. Our mission is to create a suite of services that simplify life, enhance efficiency, and drive progress. We start with our premier service, Driver Spot, and have an exciting roadmap of future offerings that will further transform how you live and work.</p>
    </div>
    <div class="image-container">
      <Lottie animationData={headeranimation} loop={true} className="img" />
    </div>
  </div>
</header>

  );
};

export default Header;
