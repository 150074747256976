import React from 'react';
import Header from './Header';
import './Home.css';
import driverspotlogo from './assets/driverspot.png'
import getapp from '../assets/getapp.png'
import Contact from './Contact';
const Home = () => {
  return (
    <div className='home'>
      <Header />
     

<div class="container">
        <h2>Why Choose Shawave?</h2>
        <div class="card">
            <h3>Innovation at Heart</h3>
            <p>We are dedicated to developing cutting-edge technologies that address real-world problems.</p>
        </div>
        <div class="card">
            <h3>Customer-Centric Approach</h3>
            <p>Your needs drive our solutions. We prioritize user experience and customer satisfaction.</p>
        </div>
        <div class="card">
            <h3>Scalable Solutions</h3>
            <p>Our services are designed to grow with your business, ensuring seamless scalability as your needs evolve.</p>
        </div>
        <div class="card">
            <h3>Expert Support</h3>
            <p>Our team of experts is always ready to assist you, providing top-notch support and guidance whenever you need it.</p>
        </div>
    </div>

     <h2>Our Services</h2>
      <section class="home-section">
    <img src={driverspotlogo} alt="logo" className='servicelogo'/>
    <h3>Your Ultimate Driver Management Mobile App</h3>
    <div className='feature-card-section'>
    <div class="feature-card">
        <p><b>Afterparty Service:</b> Ensure safe and reliable transportation after your events with our dedicated afterparty service.</p>
    </div>
    <div class="feature-card">
        <p><b>Intercity Service:</b> Experience seamless travel between cities with our efficient intercity service.</p>
    </div>
    <div class="feature-card">
        <p><b>Outstation Service:</b> Plan your long-distance journeys with confidence using our comprehensive outstation service.</p>
    </div>
    
    <div class="feature-card">
        <p><b>Safety & Compliance:</b> Ensure your drivers adhere to safety standards and regulatory requirements.</p>
    </div>
    </div>
</section>
 <div className='downloadsection'>
    <div className='downloadsectionright'>
    <h4 className='downloadtitle'>Streamline Your Vehicle Experience</h4>
    <h5 className='downloadsubtitle'>Get the DriverSpot app on iOS or Android devices to enjoy smooth booking and easily monitor all your reservations.</h5>
   
    <div className='buttonsection'>
     <img src={getapp} style={{height:100,width:100}}/>
    <a href=''>
        <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" className='downloadimg1'/>
        </a>
        <a href=''>
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png" className='downloadimg2'/>
        </a>
    </div>
    </div>
    </div>
    </div>
  );
};

export default Home;
