import React, { useState } from 'react';
import DriverDashboard from './DriverDashboard';
import './Login.css';

const Login = () => {
    const [login,setLogin]=useState(false)
  return (
      login?( 
              <DriverDashboard/>
      ):(
          <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" required />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
      )
    
  );
};

export default Login;
