import React, { useEffect, useState } from 'react'
import './DriverDashboard.css'
export default function DriverDashboard() {
   const mockDriverData = [
  {
    id: 1,
    name: 'John Doe',
    age: 30,
    dob: '1994-06-15',
    email: 'john@example.com',
    gender: 'Male',
    mobile: '12',
    license: 'AB123456',
    adhar: '123456789012',
    rating: 4.5,
    category: 'Senior',
    experience: 5,
    afterPartyRides: true,
    intracityRides: true,
    outstationRides: true,
    rideDays: 'Monday to Friday',
    accountStatus: 'Active',
    type: 'Full-time',
     noofafterPartyRides: 100,
    noofintracityRides: 200,
    noofoutstationRides: 50,
  },
  // Add more mock data as needed
];
 const [searchTerm, setSearchTerm] = useState('');
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [driverDetails, setDriverDetails] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const handleSearch = () => {
    const driver = mockDriverData.find(d => d.mobile === searchTerm);
    setSelectedDriver(driver);
    setDriverDetails(driver);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDriverDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (selectedDriver) {
      setIsSaveDisabled(JSON.stringify(selectedDriver) === JSON.stringify(driverDetails));
    }
  }, [driverDetails, selectedDriver]);

  const handleSave = () => {
    alert('Driver details saved!');
    // Here, you would typically send the updated details to the backend server
  };

  return (
     
    <div className="dashboard-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by mobile number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {selectedDriver ? (
        <div className="driver-details">

          <div className="details-section">
            <h3>Personal Information</h3>
            <div className="form-group">
              <label>Name</label>
              <input type="text" name="name" value={driverDetails.name} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Age</label>
              <input type="number" name="age" value={driverDetails.age} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Date of Birth</label>
              <input type="date" name="dob" value={driverDetails.dob} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input type="email" name="email" value={driverDetails.email} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Gender</label>
              <select name="gender" value={driverDetails.gender} onChange={handleChange}>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label>Mobile</label>
              <input type="text" name="mobile" value={driverDetails.mobile} onChange={handleChange} />
            </div>
          </div>

          <div className="details-section">
            <h3>Identification</h3>
            <div className="form-group">
              <label>License Number</label>
              <input type="text" name="license" value={driverDetails.license} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Adhar Number</label>
              <input type="text" name="adhar" value={driverDetails.adhar} onChange={handleChange} />
            </div>
          </div>

          <div className="details-section">
            <h3>Professional Information</h3>
            <div className="form-group">
              <label>Rating</label>
              <input type="number" step="0.1" name="rating" value={driverDetails.rating} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Category</label>
              <input type="text" name="category" value={driverDetails.category} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Experience (years)</label>

              <input type="number" name="experience" value={driverDetails.experience} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>After Party Rides</label>
               <select name="accountStatus" value={driverDetails.afterPartyRides} onChange={handleChange}>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
            <div className="form-group">
              <label>Intracity Rides</label>
               <select name="accountStatus" value={driverDetails.intracityRides} onChange={handleChange}>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
            <div className="form-group">
              <label>Outstation Rides</label>
               <select name="accountStatus" value={driverDetails.outstationRides} onChange={handleChange}>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
             
            </div>

            <div className="form-group">
              <label>No of After Party Rides</label>
              <input type="number" name="afterPartyRides" value={driverDetails.noofafterPartyRides} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>No of Intracity Rides</label>
              <input type="number" name="intracityRides" value={driverDetails.noofintracityRides} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>No of Outstation Rides</label>
              <input type="number" name="outstationRides" value={driverDetails.noofoutstationRides} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Ride Days</label>
              <input type="text" name="rideDays" value={driverDetails.rideDays} onChange={handleChange} />
            </div>
          </div>

          <div className="details-section">
            <h3>Account Information</h3>
            <div className="form-group">
              <label>Account Status</label>
              <select name="accountStatus" value={driverDetails.accountStatus} onChange={handleChange}>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Suspended">Suspended</option>
              </select>

            </div>
            <div className="form-group">
              <label>Driver Type</label>
              <select name="type" value={driverDetails.type} onChange={handleChange}>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contractor">Contractor</option>
              </select>
            </div>
          </div>

          <button 
            onClick={handleSave} 
            className="save-button" 
            disabled={isSaveDisabled}
          >
            Save
          </button>
        </div>
      ) : (
        <p>No driver found</p>
      )}
    </div>
  );
};
