import React from 'react';
import './About.css';
import sunanthImage from '../assets/sunanth.jpeg';
import rushikanthImage from '../assets/rushi1.jpeg';
import { FaCheckCircle } from 'react-icons/fa';
import icon from '../assets/icon.png'
export default function AboutUs  ()  {
  return (
    <div className="about-us-container">
      {/* Company Description Section */}
      <section className="company-section">
        <h2>About Us</h2>
        <div className='aboutsection'>
        <img src={icon} alt="icon" />
        <div className='p'>
        <p>
          At <span className="highlight">Shawave Technologies</span>, we are dedicated to developing innovative solutions that tackle everyday challenges. Our mission is to create products that enhance security, efficiency, and convenience for our clients. From cutting-edge technology to user-friendly applications, we strive to make a meaningful impact on people's lives.
        </p>
         <div className="features-list">
        
          <h3 className='features-list1'><FaCheckCircle className="icon" /> Innovative Technologies</h3>
          <h3 className='features-list1'><FaCheckCircle className="icon" /> Customer-Centric Approach</h3>
          <h3 className='features-list1'><FaCheckCircle className="icon" /> Reliable Support</h3>
        </div>
        </div>
        </div>
       
       
      </section>

      {/* Founders Section */}
      <section className="founders-section">
        <h2>Our Founders</h2>
        <div className="founders">
          {/* Founder 1 */}
          <div className="founder-card">
            <div className="founder-image-container">
              <img src={sunanthImage} alt="Sunanth" className="founder-image" />
            </div>
            <h3>Sunanth</h3>
            <p className="founder-title">Founder</p>
            <p className="founder-description">
              Sunanth is a visionary leader with a passion for innovation. With extensive experience in technology development, he is committed to creating groundbreaking solutions that have a real impact on people's lives.
            </p>
          </div>

          {/* Founder 2 */}
          <div className="founder-card">
            <div className="founder-image-container">
              <img src={rushikanthImage} alt="Rushikanth" className="founder-image" />
            </div>
            <h3>Rushikanth</h3>
            <p className="founder-title">Co-Founder</p>
            <p className="founder-description">
              Rushikanth is a tech enthusiast and expert in software development. His technical expertise drives our company forward, ensuring that we stay ahead of the curve in a rapidly evolving industry.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};


